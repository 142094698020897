import { template as template_2632d6c8c09f4feba5d16b0d90adbfd7 } from "@ember/template-compiler";
const FKLabel = template_2632d6c8c09f4feba5d16b0d90adbfd7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
