import { template as template_d43c7b4a39c14314b3bdbd8fefcf924b } from "@ember/template-compiler";
import { concat } from "@ember/helper";
import bodyClass from "discourse/helpers/body-class";
const AddCategoryTagClasses = template_d43c7b4a39c14314b3bdbd8fefcf924b(`
  {{#if @category}}
    {{bodyClass "category" (concat "category-" @category.fullSlug)}}
  {{/if}}

  {{#each @tags as |tag|}}
    {{bodyClass (concat "tag-" tag)}}
  {{/each}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AddCategoryTagClasses;
