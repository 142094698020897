import { template as template_02bac7dda18b40d0a30618c8391a4643 } from "@ember/template-compiler";
const EmptyState = template_02bac7dda18b40d0a30618c8391a4643(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
